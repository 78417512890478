<template>
  <div class="system-settings">
    <b-tabs
      :value="tabIndex"
      nav-class="nav-left"
      pills
      vertical
      @input="handleTabChange"
    >
      <template v-for="tab of tabs">
        <b-tab
          :key="tab.title"
          :active="$route.name === tab.routeName"
        >
          <template #title>
            <div class="d-flex align-items-center">
              <feather-icon
                :icon="tab.icon"
                class="mr-50"
                size="18"
              />
              <span class="font-weight-bold text-truncate d-inline-block">{{ $t(tab.title) }}</span>
            </div>
          </template>
        </b-tab>
      </template>

      <router-view />
    </b-tabs>
  </div>
</template>

<script>
import { AccountTabs } from '@/constants/profile'

export default {
  data() {
    return {
      tabIndex: null,
      tabs: [
        // {
        //   title: 'User Profile',
        //   icon: 'UserIcon',
        //   routeName: AccountTabs.USER_PROFILE,
        // },
        // {
        //   title: 'Change Password',
        //   icon: 'LockIcon',
        //   routeName: AccountTabs.CHANGE_PASSWORD,
        // },
        // {
        //   title: 'Information',
        //   icon: 'AlertCircleIcon',
        //   routeName: AccountTabs.INFORMATION,
        // },

        {
          title: 'Notifications',
          icon: 'BellIcon',
          routeName: AccountTabs.NOTIFICATION,
        },
        {
          title: 'Language',
          icon: 'GlobeIcon',
          routeName: AccountTabs.LANGUAGE,
        },
      ],
      isLoaded: false,
    }
  },
  computed: {
  },
  methods: {
    handleTabChange(index) {
      let { query } = this.$route

      if (this.tabs[index].routeName !== this.$route.name) {
        query = {}
      }

      this.$router.push({
        name: this.tabs[index].routeName,
        query,
      })
        .catch(() => null)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/system-settings.scss';
</style>
